import React, { Component, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import { iAxios } from '../../utils';
import queryString from 'query-string'
import { useDispatch } from 'react-redux';


const Login = (props) => {

    const [mode, setMode] = useState('initial');
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        let obj = queryString.parse(props.location.search);

        if (!obj.code) {
            return;
        }

        setMode('progress');
        const params = new URLSearchParams();
        params.append('code', obj.code);
        params.append('state', obj.state);
        iAxios({
            method: 'post',
            url: '/api/auth/gitlab',
            data: params
        }).then(resp => {
            const { data } = resp.data;
            localStorage.setItem('access_token', data.jwt);
            localStorage.setItem('user', JSON.stringify(data));
            // dispatch({
            //     type: 'SET_USER_INFO',
            //     data: data
            // });
            document.location.href = `/member/${data.user.username}/CurrentSprint`;
            // },2000)
            
        }).catch(ex => {
            document.location.href = '/';
            console.error(ex);
        })
    }, [props.location.search])

    return (
        <div className="w3-padding-64 w3-center">
            {mode === 'progress' && <span>Logging you in..</span>}
            {mode === 'initial' &&
                <a className="w3-button w3-round w3-purple" href="/auth/gitlab">Please Login</a>}
        </div>
    );

}


export const LoginHandler = () => { 
    useEffect(()=>{
        console.log('handling login')
        const at = localStorage.getItem('access_token');
        console.log('access token',at)
        if (at) {
            document.location.href = `cgs-copilot://login/${at}`;
        } else {
            document.location.href = '/login'
        }
    },[]);
    return <div className='w3-center w3-padding w3-margin-64'>The Copilot should open now</div>
}

export default Login