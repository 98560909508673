import React, { Component, useEffect, useRef, useState } from 'react'
import moment from 'moment';
import Dashboard from './Dashboard';
import { Spinner, TabFilter } from '../Helper';
import IssueGroup from '../perf/IssueGroup';
import MilestoneSwitch from '../perf/MilestoneSwitch';
import IssueBoard from '../widgets/IssueBoard';
import Header from '../widgets/Header';
import { paginatedFetch, formatSecs, workingDays, getMilestone, fetchData } from '../../utils';
import { Leaves } from '../perf/Leaves';
import { ClearFilterButton } from '../common/components';
import { useSelector } from 'react-redux';

const FinRow = (props) => {
    const { isAfter, content, idx } = props;
    const [isFull, showFull] = useState(false);
    const inital = content.split('\n')
    const res = inital.reduce((agg, item) => {
        if (item.indexOf('-') == 0 && isFull == false && inital.length > 1) {
            return agg;
        }
        agg.push(item);
        return agg;
    }, []);

    return <td>
        {inital.length > 1 ? <div style={{ whiteSpace: 'pre-wrap' }}>
            {res.join('\n')}
        </div> : <div>{res.join('')}</div>}
        {(inital.length != res.length || isFull) && <button onClick={e => showFull(!isFull)}
            className='w3-button w3-margin-top w3-center w3-padding-small w3-border w3-round w3-tiny ' >
            {isFull ? 'Show Less' : 'Show More'}
        </button>}
        {(idx == 0 && isAfter) && <div>Projection</div>}
    </td>
}
const Financials = (props) => {

    const [finData, setFindAta] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [total, setTotal] = useState(null);
    useEffect(() => {
        fetchData('/api/v1/fins', {}).then(resp => { 
            setFindAta(resp.data);
            const sum = resp.data.reduce((total, item) => {
                // console.log(item['Balance'],total)
                // return total + parseFloat(item['Balance'].replace(',',''), 10)

                return 0;
            }, 0)
            // setTotal(sum)
            setHeaders(Object.keys(resp.data[0]))
        })
    }, [])
    return <div className='w3-padding'>
        <div>
            <h3 className='w3-left'>Utilisation</h3>
            {total !== null && <h6 className='w3-right'>Bonus Projection : {Math.floor(total)}</h6>}
        </div>
        <table className='w3-table   w3-border  w3-striped'>
            <thead >
                <tr className=' w3-leftbar w3-border'>
                    {headers.map(item => <th className='w3-bold'><b>{item}</b></th>)}
                </tr>
            </thead>
            <tbody>
                {finData.map((row) => {
                    const isAfter = moment(row['Month']).endOf('M').isAfter(Date.now())
                    return <tr className={isAfter ? 'w3-border-yellow w3-leftbar' : 'w3-leftbar w3-border-blue'}>
                        {headers.map((item, idx) => <FinRow idx={idx} content={row[item]} isAfter={isAfter} />)}
                    </tr>
                }
                )}
            </tbody>
        </table>

    </div>
}
const Status = (props) => {

    const userFilter = useSelector(item => item.filters.users)
    // const auth = useSelector(item => item.)
    const [stateObj, setState] = useState({
        issueList: [],
        tm: "--",
        issueCount: 'loading',
        userList: null
    });

    const reloadBtn = useRef(null);
    const processIssues = (list, meta) => {
        const map = list.reduce((acc, item) => {
            let uname = 'unAssigned';
            let name = 'Un Assigned';
            if (item.assignee) {
                uname = item.assignee.username;
                name = item.assignee.name;
            }
            if (!acc[uname]) {
                acc[uname] = new IssueGroup(uname, name);
            }
            acc[uname].addIssue(item);
            return acc;
        }, {});

        setState({
            tm: meta.tm,
            issueCount: list.length,
            issueList: list,
            userList: Object.values(map).sort((a, b) => a.totalEstimate - b.totalEstimate)
        });

        if (reloadBtn?.current) {
            reloadBtn.current.disabled = false;
        }
    }


    const fetchData = (mode = '') => {
        if (reloadBtn?.current) {
            reloadBtn.current.disabled = false;
        }
        setState({
            userList: null,
            tm: '--',
            issueCount: 'loading',
            issueList: null,
        })
        setTimeout(na => {
            paginatedFetch('/api/v1/issues', {
                page: 1,
                mode: mode,
                milestone,
            }).then(resp => {
                processIssues(resp.data, resp.meta);
            }).catch(err => {
                console.error(err);
                alert(err.message);
            });
        }, 100);
    }

    const { filter, milestone } = props?.match?.params || {};
    useEffect(() => {

        if (!milestone || !filter) {

            if (document.location.pathname != '/sprint/CurrentSprint/dashboard') {
                document.location.href = '/sprint/CurrentSprint/dashboard';
            }
        } else {
            fetchData('normal');
        }
    }, [milestone])

    let filters = ['Dashboard', 'Board', 'All', 'Open', 'Plan', 'Backlog', 'Dev', 'QA', 'Done', "Leaves", 'Utilisation'],
        content = null,
        total = 0; 
    const { userList, issueList, tm, issueCount } = stateObj;
    const milestoneObj = getMilestone();

    if (userList == null) {
        content = <Spinner />;
    } else if (filter == 'utilisation') {
        content = <div>
            <Financials />
        </div>
    } else if (userList.length == 0) {
        content = <div className="w3-center w3-padding-64 w3-block" >
            Empty Result
        </div>
    } else if (filter == 'leaves') {
        content = <div>
            <Leaves />
        </div>
    } else if (filter == 'board') {
        content = <div>
            <IssueBoard issues={issueList} userFilter={userFilter} />
        </div>
    } else if (filter == 'dashboard' || filter == '' || !filter) {
        total = 0;
        content = <Dashboard issueList={issueList}
            userList={userList} />
    } else {
        content = userList.reduce((acc, item) => {
            if (userFilter.length > 0) {
                if (!userFilter.some(userId => item.uname == userId)) {
                    return acc;
                }
            }
            const view = item.renderView(filter);
            if (item.filtered > 0) {
                acc.push(view);
            }
            return acc;
        }, []);
        if (content.length == 0) {
            content = <div className="w3-center w3-light-grey w3-padding-64 w3-block" >
                Current selection has no items to show.
            </div>
        } else {
            content =
                <div className="cgs-table cgs">
                    {content}
                </div>
        }
        total = userList.reduce((acc, item) => acc + item.filtered, 0);
    }

    let title = "";
    if (milestoneObj) {
        title = moment(milestoneObj.start).format('Do MMM') + ' to '
            + moment(milestoneObj.end).format('Do MMM');
        title = title += ", " + formatSecs(workingDays(milestoneObj.start, milestoneObj.end) * 8 * 3600) + ",";
    }

    return (<div>
        <Header title="Focus Dashboard" leftItem={<div className="w3-right w3-right-align"
            style={{ padding: "6px" }}>Members need to plan for min of 32 hrs a week
            <br />
            <i className="w3-text-aqua">{title} {issueCount} issues, Updated {
                tm == '--' ? tm : moment(tm).fromNow()} </i>
        </div>} />
        <div className="w3-bar cgs w3-theme-d2">
            <div className="w3-left">
                <TabFilter filters={filters}
                    current={filter}
                    base={`/sprint/${milestone}`}
                    count={total} />
            </div>
            <div className="w3-right">

                <ClearFilterButton clsName={'w3-padding w3-left w3-white'} />

                <a className="w3-button w3-bar-item w3-green"
                    href={`https://gitlab.ceegees.in/groups/ceegees/-/boards?scope=all&utf8=%E2%9C%93&state=opened&milestone_title=${milestone}`}
                    target="_blank">Update</a>
                <button
                    ref={reloadBtn}
                    onClick={e => fetchData('refresh')}
                    className="w3-bar-item w3-blue w3-bar-item w3-button">
                    <i className="ion-refresh"></i>
                </button>
                <MilestoneSwitch
                    urlScheme={`/sprint/MILESTONE/${filter}`} />

            </div>
        </div>
        <div>
            <div className="w3-responsive" style={{ minHeight: "100vh" }}>
                {content}
            </div>
        </div>
    </div>)

}

export default Status;